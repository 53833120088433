import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vr',
  templateUrl: './vr.component.html',
  styleUrls: ['./vr.component.css']
})
export class VrComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
