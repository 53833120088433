import {AfterViewInit, Component, OnInit, VERSION, ViewChild } from '@angular/core';
// When no stackblitz project, uncomment this line and comment line 56 in polyfills.ts.
 import '@google/model-viewer';
 import * as THREE from "three"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { USDZExporter } from 'three/examples/jsm/exporters/USDZExporter';
import { DomSanitizer } from '@angular/platform-browser';
import {FormControl, Validators} from '@angular/forms';


@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit{

  @ViewChild('modelViewer') modelViewer;
  @ViewChild('link') link;
  fileUrl: any

  /**
   * @title Input with error messages
   */
  emailFormControl= new FormControl('', [Validators.email]);


  constructor(private sanitizer: DomSanitizer) {

  }

  ngOnInit(){

  }



  ngAfterViewInit(){

    /*
    console.log("hi", document.readyState);
    document.addEventListener('readystatechange',function (ev) {
      console.log("statechange", document.readyState);
    })
    document.addEventListener("DOMContentLoaded", function(ev){
      console.log("DOMLOADED", ev);
    });

     */
  }


  descargarUSDZ(){

    console.log(this.modelViewer)
    const loader = new GLTFLoader();


    const afterload = object => { console.log(object);
      var url= window.URL.createObjectURL(object);
      window.open(url)
    }


    loader.load( 'assets/modelo_habita_test_3.glb', async function ( gltf ) {

      console.log("modelo:", gltf)
      const exporter = new USDZExporter();
      const arraybuffer = await exporter.parse( gltf.scene );
      const blob = new Blob( [ arraybuffer ], { type: 'application/octet-stream' } );
      console.log(blob)
      afterload(blob)
      console.log("Finished");
    } );
  }



  lauchAR(){
    console.log(this.modelViewer)
    this.modelViewer.nativeElement.activateAR();

  }


}
