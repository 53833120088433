import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  models = [{id: 1, name: 'Modelo Realidad Aumentada'}, {id: 2, name: 'Modelo Realidad Virutal'}, {id: 3, name: 'Modelo Image Target'}]

  constructor() { }

  ngOnInit(): void {
  }

}
