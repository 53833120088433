import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {FormComponent} from "../../components/form/form.component";

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.css']
})
export class ModelComponent implements OnInit, AfterViewInit {

  @ViewChild('modelViewer') modelViewer;

  id: number;
  private sub: any;

  constructor(private route: ActivatedRoute, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
    });

    const dialogRef = this.dialog.open(FormComponent);

  }

  ngAfterViewInit(){
    console.log(this.modelViewer.nativeElement.canActivateAR)
    if (!this.modelViewer.nativeElement.canActivateAR) this.modelViewer.nativeElement.dismissPoster();
    this.modelViewer.nativeElement.addEventListener('load', function(evt) {
      console.log(evt);
      console.log("MODELO CARGADO");
    })

  }

  launchAR(){
    console.log(this.modelViewer)
    if (this.modelViewer.nativeElement.canActivateAR){
      this.modelViewer.nativeElement.activateAR();
    }

  }

}
