import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule} from "@angular/router";
import {LandingComponent} from "./pages/landing/landing.component";
import {ModelComponent} from "./pages/model/model.component";
import {ImageTargetComponent} from "./pages/image-target/image-target.component";
import {VrComponent} from "./pages/vr/vr.component";

const routes: Routes = [
  { path: '', component: LandingComponent},
  { path: 'ar', component: ModelComponent},
  { path: 'vr', component: VrComponent},
  { path: 'image-target', component: ImageTargetComponent}
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
